<template>
	<div class="d-flex" :class="{disabled}">
		<button :disabled="disabled" class="btn btn-link px-2" @click.prevent="prevSymbol" v-if="showButtons">
			<i class="fa fa-caret-left"></i>
		</button>
		<symbol-icon :config="config" :compact="compact" :symbol="currentSymbol"
					 :class="{'bg-danger': !enableNull && currentSymbol.id === null}"></symbol-icon>
		<button :disabled="disabled" class="btn btn-link px-2" @click.prevent="nextSymbol" v-if="showButtons">
			<i class="fa fa-caret-right"></i>
		</button>
	</div>
</template>
<script>
	import SymbolIcon from "./symbol-icon";

	export default {
		props: {
			disabled: {
				type: Boolean,
				default: false
			},
			enableNull: {
				type: Boolean,
				default: false
			},
			showButtons: {
				type: Boolean,
				default: true
			},
			value: {
				type: String
			},
			compact: {
				type: Boolean,
				default: false
			},
			config: {
				type: Object
			}
		},
		components: {SymbolIcon},
		computed: {
			model: {
				get() {
					return this.value
				},
				set(symbol) {
					if (!this.disabled) {
						this.$emit('input', symbol ? `${symbol}` : null)
					}
				}
			},
			symbols() {
				if (this.enableNull) {
					return [null, ...this.config.symbols]
				} else {
					return this.config.symbols
				}
			},
			currentSymbol() {
				return this.symbols[this.currentIndex]
			},
			currentIndex: {
				get() {
					return this.symbols.findIndex(s => {
						if (s !== null) {
							return this.model === s.id
						} else {
							return this.model === null
						}
					})
				},
				set(_val) {
					const symbol = this.symbols[_val]
					if (symbol) {
						this.model = symbol.id
					} else {
						this.model = null
					}
				}
			}
		},
		mounted() {
			if (!this.enableNull && this.value === null) {
				this.model = this.symbols[0].id
			}
		},
		methods: {
			prevSymbol() {
				let nextIndex = (this.currentIndex - 1) % this.symbols.length
				if (nextIndex < 0) {
					nextIndex = this.symbols.length - 1
				}
				this.currentIndex = nextIndex
			},
			nextSymbol() {
				this.currentIndex = (this.currentIndex + 1) % this.symbols.length
			},
		}
	}
</script>
