<template>
	<div class="payline" v-if="config">
		<grid :config="config" class="pb-2" :cell-size="cellSize" :disabled="!enabled" :classes="classes" :labels="labels" @select="toggleIndex"></grid>
	</div>
</template>

<script>
	import Grid from "./grid";

	export default {
		props: {
			value: {
				type: Array
			},
			enabled: {
				type: Boolean,
				default: true
			},
			selectedClass: {
				type: String,
				default: 'selected'
			},
			cellSize: {
				type: String,
				default: '16px'
			},
			config: {
				type: Object
			}
		},
		methods: {
			getIndex(column, row) {
				return column + row * this.reels
			},
			indexToPosition(index) {
				let reel = index % this.reels;
				let line = Math.floor(index / this.reels);
				return [reel, line]
			},
			toggleIndex(index) {
				if (this.enabled) {
					let position = this.indexToPosition(index)
					console.log(index, this.payline[position[0]])

					let new_value = null
					if (this.payline[position[0]] !== index) {
						new_value = index
					}

					this.$set(this.payline, position[0], new_value)
				}
			}
		},
		mounted() {
			this.payline = this.payline.slice(0, this.reels)
		},
		computed: {
			classes() {
				let tmp = {}

				this.value.forEach(index => {
					tmp[index] = this.selectedClass
				})

				for (let i = 0; i < this.reels; i++) {
					if (this.payline[i] === undefined || this.payline[i] === null) {
						for (let r = 0; r < this.rows; r++) {
							tmp[this.getIndex(i, r)] = "missing"
						}
					}
				}

				return tmp
			},
			labels() {
				let tmp = {}

				let counter = 0

				this.value.forEach(index => {
					tmp[index] = `${counter++ + 1}`
				})

				for (let i = 0; i < this.reels; i++) {
					if (this.payline[i] === undefined || this.payline[i] === null) {
						for (let r = 0; r < this.rows; r++) {
							tmp[this.getIndex(i, r)] = "?"
						}
					}
				}

				return tmp
			},
			reels() {
				return this.config.reels.length
			},
			rows() {
				return this.config.visibleLines
			},
			payline: {
				get() {
					return this.value
				},
				set(line) {
					line.slice(0, this.reels)
					if (line.join("-") !== this.payline.join("-")) {
						this.$emit("input",)
					}
				}
			}
		},
		components: {Grid}
	}
</script>
