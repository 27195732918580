import { render, staticRenderFns } from "./cage.vue?vue&type=template&id=3468e39a&scoped=true&"
import script from "./cage.vue?vue&type=script&lang=js&"
export * from "./cage.vue?vue&type=script&lang=js&"
import style0 from "./cage.vue?vue&type=style&index=0&id=3468e39a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3468e39a",
  null
  
)

export default component.exports