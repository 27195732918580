<template>
    <div class="d-flex align-items-center justify-content-around w-100">
		<div>
			<div class="text-small">Win</div>
			<div class="font-weight-bold">#{{ (selectedIndex+1) }}</div>
		</div>
		<div>
			<div class="text-small" v-text="selectedWin.type"></div>
			<div class="font-weight-bold" v-text="selectedWin.subtype"></div>
		</div>
		<div>
			<div class="text-small">Prize</div>
			<prize-viewer class="font-weight-bold" :prize="selectedWin.prize"></prize-viewer>
		</div>
		<div>
			<div class="text-small">Stage</div>
			<div class="font-weight-bold" v-text="selectedWin.stage"></div>
		</div>
		<div>
			<div class="text-small">Win</div>
			<div class="font-weight-bold" v-text="selectedWin.winValue"></div>
		</div>
		<div v-if="selectedWin.payline">
			<payline-viewer :config="config" :selected-class="`payline-${selectedWin.payline}`"
							cell-size="16px"
							:enabled="false"
							:value="config.paytable.paylines[selectedWin.payline-1]"></payline-viewer>
		</div>
	</div>
</template>
<script>
    import PaylineViewer from "../slot-config-editor/paylines/payline-viewer"
    import PrizeViewer from "../slot-config-editor/prize/prize-viewer"

    export default {
        name: 'win-resume',
        components: {PaylineViewer, PrizeViewer},
        props: {
            config: {},
            selectedIndex: {},
            selectedWin: {}
        }
    }
</script>
<style scoped>

    .gamble-area button:disabled {
        background-color: #c7c7c7;
        border-color: gray;
        cursor: not-allowed;
    }

</style>
