<template>
	<div class="row">
		<div class="col-sm col-xl-5">
			<div class="d-flex justify-content-center align-items-center"
					 :class="[{loading, 'bg-light': wins && wins.length === 0}, selectedWin && selectedWin.payline ? `darker payline-${selectedWin.payline}` : 'bg-dark']">
				<div class="px-3 cursor-pointer" @click="previousWin">
					<i class="fa fa-caret-left text-white fa-5x"></i>
				</div>
				<cage :config="config" class="animated justify-content-center w-100"
							v-model="computedReels" :modified-reels="modifiedReels"
							@change="reelsModified = true" :winning-positions="winningPositions">
				</cage>
				<div class="px-3 cursor-pointer" @click="nextWin">
					<i class="fa fa-caret-right text-white fa-5x"></i>
				</div>
			</div>
			<div>
				<div class="d-flex align-items-center text-center py-2 text-small text-uppercase animated text-white"
						 :class="selectedWin ? `darker payline-${selectedWin.payline}` : 'bg-success'"
						 style="height: 64px"
				>
					<win-resume v-if="selectedWin" :config="config" :selected-index="selectedIndex"
											:selected-win="selectedWin"/>
					<div
							:class="{'text-white bg-primary': wins.length > 0, 'bg-light text-dark': wins.length === 0 }"
							class="w-100 d-flex justify-content-around align-items-center"
							style="height: 64px"
							v-else
					>
						<div>
							<div class="text-small">Winnings</div>
							<div class="font-weight-bold" v-text="wins.length"></div>
						</div>
						<div>
							<div class="text-small">Total win</div>
							<div class="font-weight-bold" v-text="wins.reduce((c, w) => c + w.winValue, 0)"></div>
						</div>
					</div>
				</div>
			</div>
			<slot></slot>
		</div>
		<div class="col">
			<last-win :config="config" v-model="selectedIndex"
								:wins="wins"></last-win>
		</div>
	</div>
</template>
<script>
import Cage from "./cage"
import LastWin from "./last-win";
import WinResume from "./WinResume";

export default {
	name: 'stage-viewer',
	components: {WinResume, Cage, LastWin},
	data: () => ({
		latestReels: '',
		selectedIndex: null,
		winningAnimationInterval: null
	}),
	props: {
		reels: {},
		config: {},
		stages: {
			type: Array
		},
		wins: {
			type: Array,
			default: () => []
		},
		modifiedReels: {},
		loading: {},
	},
	mounted() {
		this.setupWinningAnimation()
		this.$nextTick(() => this.latestReels = JSON.stringify(this.reels))
	},
	methods: {
		clearWinningAnimation() {
			if (this.winningAnimationInterval !== null) {
				clearTimeout(this.winningAnimationInterval)
			}
		},
		setupWinningAnimation() {

			this.clearWinningAnimation()

			if (this.wins.length > 1) {
				this.winningAnimationInterval = setTimeout(this.nextWin, 1000 * 2)
			}
		},
		previousWin() {
			if (this.selectedIndex === null) {
				this.selectedIndex = this.wins.length - 1;
			} else if (this.selectedIndex > 0) {
				this.selectedIndex--;
			} else {
				this.selectedIndex = null;
			}
		},
		nextWin() {
			if (this.selectedIndex === null) {
				this.selectedIndex = 0;
			} else if (this.selectedIndex < this.wins.length - 1) {
				this.selectedIndex++;
			} else {
				this.selectedIndex = null;
			}
		},
	},
	computed: {
		selectedWin() {
			if (this.selectedIndex !== undefined && this.selectedIndex !== null) {
				return this.wins[this.selectedIndex]
			}
			return null
		},
		computedReels: {
			get() {
				let reels = this.reels.slice()

				if (this.selectedWin !== null && this.selectedWin !== undefined && this.selectedWin.stage !== undefined && this.stages !== undefined) {
					reels = this.stages[this.selectedWin.stage - 1].reels.slice()
				}

				const padding_lines = parseInt(this.config.paddingLines)

				if (this.modifiedReels !== undefined) {
					for (let i = 0; i < this.modifiedReels.length; i++) {
						const current_reel = this.modifiedReels[i]
						for (let j = 0; j < current_reel.length; j++) {
							if (current_reel[j]) {
								reels[i][padding_lines + j] = `${current_reel[j]}`
							}
						}
					}
				}

				return reels
			},
			set(value) {
				this.computedLastResult.state.reels = value
			},
		},
		winningPositions() {
			let positions = []

			if (this.reelsModified) {
			} else if (this.selectedWin) {
				positions.push(...this.selectedWin.coords)
			} else {
				if (this.stages === undefined || this.stages.length === 0) {
					this.wins.forEach(w => positions.push(...w.coords))
				}
			}

			return positions;
		},
		reelsModified() {
			return false;//this.latestReels !== JSON.stringify(this.reels)
		},
	}
}
</script>
<style scoped>

.gamble-area button:disabled {
	background-color: #c7c7c7;
	border-color: gray;
	cursor: not-allowed;
}

</style>
