<template>
	<div>
		<div class="line d-flex" v-for="row in rows">
			<div class="cell cursor-pointer text-small d-flex justify-content-center align-items-center"
				 :class="getCustomClasses(getIndex(reel-1, row-1))"
				 v-for="reel in reels" @click="onClick(reel-1, row-1)"
				 v-text="getCustomLabels(getIndex(reel-1, row-1))"
				 :style="{width: cellSize, height: cellSize}">
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			classes: {
				type: Object,
				default: () => {
					return {}
				}
			},
			labels: {
				type: Object,
				default: () => {
					return {}
				}
			},
			cellSize: {
				type: String,
				default: "16px"
			},
			disabled: {
				type: Boolean,
				default: false
			},
			config: {
				type: Object
			}
		},
		methods: {
			getIndex(column, row) {
				return column + row * this.reels
			},
			getCustomClasses(index) {
				let c = []

				if (this.disabled) {
					c.push('disabled')
				}

				if (this.classes[index] !== undefined) {
					c.push(this.classes[index])
				}
				return c
			},
			getCustomLabels(index) {
				if (this.labels[index] !== undefined) {
					return this.labels[index]
				}
			},
			onClick(x, y) {
				if (!this.disabled) {
					this.$emit('select', this.getIndex(x, y))
				}
			}
		},
		computed: {
			rows() {
				return parseInt(this.config.visibleLines)
			},
			reels() {
				return this.config.reels.length
			},
		},
	}
</script>

<style scoped lang="scss">
	@import "@/assets/style/_palette";

	.cell {
		display: block;
		background-color: $color-grey;
		margin: .5px;
		color: white;

		&.missing {
			background-color: lighten($color-danger, 30%);
		}

		&:not(.disabled):hover {
			background-color: $color-success;
		}

		&.selected {
			background-color: $color-primary;

			&:hover {
				background-color: $color-danger;
			}
		}
	}
</style>
