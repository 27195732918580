<template>
	<div class="d-inline-flex">
		<div class="reel" v-for="(reel, reel_id) in reels">
			<div v-for="(cell, cell_id) in reel">
				<symbol-switcher :reel="reel_id" :cell="cell_id" :config="config" class="cell animated my-1"
								 v-model="reels[reel_id][cell_id]"
								 :show-buttons="showButtons"
								 :disabled="(cell_id < paddingLines || (reel.length - cell_id - 1) < paddingLines)"
								 :class="symbolClasses(reel_id, cell_id)"></symbol-switcher>
			</div>
		</div>
	</div>
</template>

<script>
	import SymbolIcon from "../slot-config-editor/symbols/symbol-icon";
	import SymbolSwitcher from "../slot-config-editor/symbols/symbol-switcher";

	export default {
		components: {SymbolSwitcher, SymbolIcon},
		props: {
			value: {
				type: Array,
				default: () => []
			},
			winningPositions: {
				type: Array,
				default: () => []
			},
			showButtons: {
				type: Boolean,
				default: true
			},
			config: {
				type: Object
			},
			modifiedReels: {
				type: Array
			}
		},
		computed: {
			symbols() {
				return this.config.symbols
			},
			paddingLines() {
				return this.config.paddingLines
			},
			reels: {
				get() {
					return this.value
				},
				set(reels) {
					this.$emit('input', reels)
				}
			}
		},
		methods: {
			symbolClasses(reel, row) {

				let _classes = {}

				_classes['mx-2'] = !this.showButtons;

				if (this.winningPositions.length > 0) {
					let isWinning = this.winningPosition(reel, row)

					_classes = {
						..._classes,
						winning: isWinning,
						losing: !isWinning
					}
				}

				if (this.modifiedReels) {
					let modified_symbol = this.modifiedReels[reel][row - parseInt(this.config.paddingLines)]
					_classes['modified-symbol'] = modified_symbol !== null && modified_symbol !== undefined
				}

				return _classes

			},
			winningPosition(reel, row) {
				return this.winningPositions.some((wp) => {
					return wp.x === reel && wp.y === row - this.paddingLines
				})
			},
			getSymbolById(symbol_id) {
				return this.symbols.filter(s => s.id === symbol_id)[0]
			}
		},

	}
</script>

<style scoped>
	.cell.disabled {
		opacity: 0.05;
	}
</style>
