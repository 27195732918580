<template>
	<div :class="{compact}">
		<table class="table" :class="{'table-shrikned': compact}">
			<thead>
			<tr>
				<td>Symbol</td>
				<td>Count</td>
				<td>Type</td>
				<td>Payline</td>
				<td>Prize</td>
			</tr>
			</thead>
			<tr class="font-weight-bold">
				<td></td>
				<td></td>
				<td></td>
				<td>TOTAL</td>
				<td v-text="wins.reduce((c, w) => c + w.winValue, 0)"></td>
			</tr>
			<template v-if="wins.length > 0">
				<tr v-for="(win, index) in wins"
						@click="toggleSelectedIndex(index)"
						class="cursor-pointer animated win-row"
						:class="value === index ? `darker payline-${win.payline}` : ''">
					<td class="align-middle text-center">
						<div class="d-flex h-100 align-items-center">
							<symbol-icon :config="config" :compact="compact" class="d-inline-block"
													 :symbol="getSymbolById(win.symbol)"></symbol-icon>
						</div>
					</td>
					<td class="align-middle" v-text="win.symbolsCount">
					</td>
					<td class="align-middle">
						<div class="text-uppercase overflow-hidden text-nowrap d-inline px-3 py-1 badge text-small"
								 v-text="`${win.type} ${win.subtype}`"
								 :class="[win.payline ? `text-white payline-${win.payline}` : '']"></div>
					</td>
					<td class="align-middle text-center">
						<div class="d-flex flex-column justify-content-center align-items-center" v-if="win.payline">
							<payline-viewer :config="config" :cell-size="compact ? '10px' : '16px'"
															:selected-class="`payline-${win.payline}`" class="d-block"
															:enabled="false"
															:value="getPaylineById(win.payline)"></payline-viewer>
							<div class="text-center text-small text-light">{{ win.payline | numeral("Oo") }}</div>
						</div>
					</td>
					<td class="align-middle">
						<div class="bg-light text-dark text-small p-1">
							<prize-viewer :prize="win.prize"></prize-viewer>
							<div class="font-weight-bold bg-success text-white p-2" v-if="win.bonus_multiplier !== undefined && win.bonus_multiplier > 1">
								BONUS MULTIPLIER X{{ win.bonus_multiplier }}
							</div>
						</div>
						<div v-text="`Amount: ${win.winValue}`" v-if="win.winValue > 0"></div>
					</td>
				</tr>
				<tfoot>
				<tr>
					<td colspan="3">
						<div v-for="(details, winType) in winTypes" class="bg-light my-2 p-2">
							<div>
								<strong>{{ winType }} wins count: </strong>{{ details.counter }}
							</div>
							<div>
								<strong>{{ winType }} wins amount: </strong>{{ details.amount }}
							</div>
						</div>
					</td>
					<td class="align-middle text-center">TOTAL</td>
					<td class="align-middle text-center" v-text="wins.reduce((c, w) => c + w.winValue, 0)"></td>
				</tr>
				</tfoot>
			</template>
			<tr v-else>
				<td colspan="5" class="text-center p-5">
					<h5 class="text-light">No winnings found</h5>
				</td>
			</tr>
		</table>
	</div>
</template>

<script>
import SymbolIcon from "../slot-config-editor/symbols/symbol-icon";
import PaylineViewer from "../slot-config-editor/paylines/payline-viewer";
import PrizeViewer from "../slot-config-editor/prize/prize-viewer";

export default {
	components: {PrizeViewer, PaylineViewer, SymbolIcon},
	props: {
		wins: {
			type: Array,
			default: () => []
		},
		compact: {
			type: Boolean,
			default: false
		},
		value: {
			type: Number,
			default: null
		},
		config: {
			type: Object
		}
	},
	computed: {
		winTypes() {
			let ret_obj = {}


			this.wins.forEach(function (w) {
				if (ret_obj[w.type] === undefined) {
					ret_obj[w.type] = {
						counter: 0,
						amount: 0
					}
				}

				ret_obj[w.type].counter++;
				ret_obj[w.type].amount += w.winValue;

			})

			return ret_obj

		},
		symbols() {
			return this.config.symbols
		},
		paylines() {
			return this.config.paytable.paylines
		}
	},
	methods: {
		getSymbolById(symbol_id) {
			return this.symbols.filter(s => s.id === symbol_id)[0]
		},
		getPaylineById(payline_id) {
			return this.paylines[payline_id - 1]
		},
		toggleSelectedIndex(index) {
			if (index === this.value) {
				index = null
			}

			this.$emit('input', index)
		}
	},
}
</script>

<style scoped lang="scss">
@import "@/assets/style/_palette";

.win-row:hover {
	background: #f6f6f6;
}

.compact {

}
</style>
